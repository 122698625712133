import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export default class Header extends Component {
    render() {
        return (
        <div>
            <header className="navbar pt-2">
                <section className="navbar-section">
                    <NavLink className="navbar-brand mr-2" to={"/"}>Semaine</NavLink>
                    <NavLink className="btn btn-link" to={"/repas"}>Liste</NavLink>
                    <NavLink className="btn btn-link" to={"/repas/add"}>Ajouter</NavLink>
                </section>
            </header>
        </div>
        )
    }
}
