import React, { Component } from 'react'

import Header from '../components/Header';
import RepasList from '../components/RepasList';


export default class RepasLayout extends Component {
    render() {
        return (
        <div>
            <Header />
            <RepasList />
        </div>
        )
    }
}