import React, { Component } from 'react'
import * as firebase from 'firebase';

import Header from '../components/Header';
import RepasEdit from '../components/RepasEdit';

export default class RepasLayout extends Component {
    constructor(props) {
        super(props);

        console.log('this.props', this.props);
        this.ID = this.props.match.params.id;
        this.repasRef = firebase.database().ref('repas/'+this.ID);

        this.state = {
            repas: null
        }
    }

    componentDidMount() {
        const _this = this;

        this.repasRef.on('value', function(snapshot) {
            let repas;

            console.log('snapshot.val()', snapshot.val());

            // repas = snapshot.val() ? Object.keys(snapshot.val()).map( key => {
            //     let obj = snapshot.val()[key];
            //     obj.id = key;
            //     return obj;
            // }) :
            // repas = [];

            _this.setState({ 
                repas: { 
                    id: _this.ID, 
                    ...snapshot.val()
                }
            })
        });
    }

    // Et on supprime le listener lorsque le composant est détruit
    componentWillUnmount() {
        this.repasRef.off();
    }

    render() {
        return (
        <div>
            <Header />    
            <RepasEdit repas={this.state.repas} />        
        </div>
        )
    }
}
          