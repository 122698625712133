import React, { Component } from 'react'
import RepasForm from './RepasForm'

export default class RepasAdd extends Component {
    render() {
        return (
            <div>
                <h3>Ajouter un repas</h3>
                <RepasForm />
            </div>
        )
    }
}
