import React, { Component } from 'react'
import * as firebase from 'firebase';

const initialState = {
  days: {
    lundi: {
      midi: '',
      soir: '',
    },
    mardi: {
      midi: '',
      soir: '',
    },
    mercredi: {
      midi: '',
      soir: '',
    },
    jeudi: {
      midi: '',
      soir: '',
    },
    vendredi: {
      midi: '',
      soir: '',
    },
    samedi: {
      midi: '',
      soir: '',
    },
    dimanche: {
      midi: '',
      soir: '',
    }
  },
  repasPickable: [],
  repasPicked: [],
  ingredients: {}
}
export default class Menu extends Component {
  constructor(props) {
    super(props);

    this.repasRef = firebase.database().ref('repas/');
    this.menuRef = firebase.database().ref('menu');

    this.pickRepas = this.pickRepas.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setRandomMenu = this.setRandomMenu.bind(this);
    this.reset = this.reset.bind(this);

    this.state = {
      ...initialState,
      repas: [],
      ready: false
    }

  }

  componentDidUpdate(prevProps, prevState) {

    // if (prevState.repasPicked.length !== this.state.repasPicked.length) {
    //   let repasPickedDiff = this.state.repas.filter(x => !this.state.repasPicked.includes(x.id));

    //   this.setState({
    //     repasPickable: repasPickedDiff
    //   })

    //   console.log('prevState.repasPicked', prevState.repasPicked);
    //   console.log('repasPickedDiff', repasPickedDiff);
    // }

    if (this.state.ready) {
      // A post entry.
      var postData = {...this.state.days};

      // Write the new post's data simultaneously in the repas list and the user's post list.
      var updates = {};
      updates['/menu'] = postData;

      firebase.database().ref().update(updates);
    }
    
  }

  componentDidMount() {
    const _this = this;

    this.repasRef.on('value', function(snapshot) {
        let repas;

        repas = snapshot.val() ? Object.keys(snapshot.val()).map( key => {
            let obj = snapshot.val()[key];
            obj.id = key;
            return obj;
        }) :
        repas = [];

        _this.setState({ repas, repasPickable: repas })

        console.log(repas);
        localStorage.setItem('repas', JSON.stringify(repas));
    });

    this.menuRef.on('value', function(snapshot) {
      let days = snapshot.val();
      console.log('days', days);
      _this.setState({ days, ready: true })
      // localStorage.setItem('repas', JSON.stringify(repas));
    });
  }
  
  componentWillUnmount() {
    this.repasRef.off();
    this.menuRef.off();
  }

  pickRepas(event) {
    const target = event.target;        
    let name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    const nameArray = name.split('.');
    const day = nameArray[1];
    const when = nameArray[2];
    
    let repasSelected = this.state.repas.filter(r => {
      return (r.id === value);
    });
    repasSelected = repasSelected[0];

    if (!repasSelected) repasSelected = '';

    this.setState({
      'days': {
          ...this.state.days,
          [day]: {
            ...this.state.days[day],
            [when]: repasSelected
          }
      }
    });
  }

  getIngredientsList() {
    let ingredients = [];
    let days = this.state.days;
    let daysKeys = Object.keys(days);

    daysKeys.forEach((key) => {
      let midi = days[key].midi;
      let soir = days[key].soir;

      if (midi && midi.ingredients) ingredients = ingredients.concat(midi.ingredients);
      if (soir && soir.ingredients) ingredients = ingredients.concat(soir.ingredients);
    });


    var ingredientsObj = {};
    ingredients.forEach(function(x) { ingredientsObj[x] = (ingredientsObj[x] || 0)+1; });

    return ingredientsObj;
  }

  getDaysAlpha() {
    return [
      'lundi',
      'mardi',
      'mercredi',
      'jeudi',
      'vendredi',
      'samedi',
      'dimanche'
    ]
  }

  getRepasAlpha() {

    const { repas } = this.state;

    const repasAlpha = [...repas].sort(function(a, b) {

      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;

      return 0;
    })

    return repasAlpha
  }

  setRandomMenu(event) {
    let repas = this.state.repas;
    let days = this.state.days;
    let daysKeys = Object.keys(days);

    let randomDays = {};

    // loop days
    daysKeys.forEach((key) => {
      var randomRepasMidi = repas[Math.floor(Math.random() * repas.length)];

      var randomRepasSoir = repas[Math.floor(Math.random() * repas.length)];

      randomDays[key] = {
        midi: randomRepasMidi,
        soir: randomRepasSoir,
      }
    });

    this.setState({
      days: randomDays
    })
  }

  reset() {
    this.setState({
      days: initialState.days
    })

    console.log('reset');
  }

  handleSubmit(event) {
    event.preventDefault();

    // A post entry.
    var postData = {...this.state.days};
    // this.setState({
    //     label: '',
    //     ingredients: []
    // })

    // Write the new post's data simultaneously in the repas list and the user's post list.
    var updates = {};
    updates['/menu'] = postData;

    return firebase.database().ref().update(updates);
  }

  render() {
    const { days, repas } = this.state;
    
    const ingredientsList = this.getIngredientsList();
    const daysAlpha = this.getDaysAlpha();
    const repasAlpha = this.getRepasAlpha();

    return (
      <div>
        <div className="btn-group btn-group-block">
          <button className="btn btn-secondary" onClick={this.setRandomMenu}>Random</button>
          <button className="btn btn-error" onClick={this.reset}>Reset</button>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th></th>
              <th>Midi</th>
              <th>Soir</th>
            </tr>
          </thead>
          <tbody>

            {daysAlpha.map((day) =>
              <tr key={"tr-"+day} className="form-group">
                <td>{day}</td>
                <td>
                  <select className="form-select" name={"days."+day+".midi"} 
                  onChange={this.pickRepas} value={ days[day].midi ? days[day].midi.id : "" }>

                  <option value="">- Choisir -</option>
                  {repasAlpha.map((repas) => {
                    return (
                      <option key={"days."+day+".midi__"+repas.id} value={repas.id}>{repas.label}</option>
                    )
                  }
                  )}

                  </select>
                </td>

                <td>
                  <select className="form-select" name={"days."+day+".soir"} 
                  onChange={this.pickRepas} value={ days[day].soir ? days[day].soir.id : ""}>

                  <option value="">- Choisir -</option>
                  {repasAlpha.map((repas) => 
                    <option key={"days."+day+".soir__"+repas.id} value={repas.id}>{repas.label}</option>
                  )}

                  </select>
                </td>

              </tr>
            )}

          </tbody>
        </table>
                    
        <div className="divider divider-section"></div>
                  
        <h3>Liste de courses</h3>
        <ul>
          { Object.keys(ingredientsList).map((key) => 
            <li key={"ingredientsList-"+key}>
              <span className="badge" data-badge={ingredientsList[key]}>{key}</span>
            </li>
          )}
        </ul>

        <h3>Liste de courses - light</h3>
        <ul>
          { Object.keys(ingredientsList).map((key) => 
            <li key={"ingredientsList-"+key}>{key}*{ingredientsList[key]}</li>
          )}
        </ul>

      </div>
    )
  }
}
