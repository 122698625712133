import Header from '../components/Header';
import RepasList from '../components/RepasList';
import RepasAdd from '../components/RepasAdd';

import React, { Component } from 'react'
import Menu from '../components/Menu';

export default class RepasLayout extends Component {
    render() {
        return (
        <div>
            <Header /> 
            <Menu />
        </div>
        )
    }
}