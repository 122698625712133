import React, { Component } from 'react'

import Header from '../components/Header';
import RepasAdd from '../components/RepasAdd';


export default class RepasLayout extends Component {
    render() {
        return (
        <div>
            <Header />    
            <RepasAdd />      
        </div>
        )
    }
}