export const categories = [
  {
    slug: "chauds",
    label: "Chauds"
  },
  {
    slug: "froids",
    label: "Froids"
  },
  {
    slug: "tartes",
    label: "Tartes"
  },
  {
    slug: "salades",
    label: "Salades"
  },
  {
    slug: "pates",
    label: "Pâtes"
  },
]
