import React, { Component } from 'react'

import RepasForm from './RepasForm'

export default class RepasAdd extends Component {
    render() {
        return (
            <div>
                <h3>Modifier le repas</h3>
                <RepasForm repas={this.props.repas} />
            </div>
        )
    }
}
