import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import 'spectre.css'
import 'spectre.css/dist/spectre-icons.css'
import './styles/App.scss';


import Home from './layouts/Home';
import Add from './layouts/Add';
import List from './layouts/List';
import Edit from './layouts/Edit';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="container grid-xs">
          <Router onUpdate={() => window.scrollTo(0, 0)}>
            <div>
              <Switch>
                <Route
                  exact
                  path='/'
                  render={(props) => <Home {...props} />}
                />
                <Route 
                  exact
                  path="/repas" 
                  render={(props) => <List {...props} />}
                />
                <Route 
                  exact
                  path="/repas/add" 
                  render={(props) => <Add {...props} />}
                />
                <Route 
                  exact
                  path="/repas/:id/edit" 
                  render={(props) => <Edit {...props} />}
                />
              </Switch>
            </div>
          </Router>
        </div>
      </div>
    );
  }
}

export default App;
