import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as firebase from 'firebase';

import Repas from './Repas';
import { categories } from '../config'

export default class RepasList extends Component {
    constructor(props) {
        super(props);

        this.deleteRepas = this.deleteRepas.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.repasRef = firebase.database().ref('repas/');

        this.state = {
            category: '',
            autocomplete: '',
            repas: []
        }
    }

    deleteRepas(id, label) {
        let shouldContinue = window.confirm('Supprimer le repas "'+label+'" ?');

        if (shouldContinue == true) {
            firebase.database().ref('repas/'+id).remove();
        }
    }

    getFilteredRepas = () => {
        const { repas, autocomplete, category } = this.state;

        let repasFiltered = [];

        const repasAlpha = [...repas].sort(function(a, b) {
            if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
            if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
            return 0;
        })
        
        repasFiltered = repasAlpha.filter(function(item) {
            return (item.label.toLowerCase().includes(autocomplete.trim().toLowerCase()))
        })

        if (category) {
    
            repasFiltered = repasFiltered.filter(function(item) {
                return (item.category === category)
            })

        }
        
        return repasFiltered
    }

    handleInputChange(event) {
        const target = event.target;        
        let value = target.type === 'checkbox' ? target.checked : target.value;
        
        let isArray = target.name.includes('[]');
        let name = target.name;
        let nameArray = name.split(',');
        
        if (target.multiple) value = [...event.target.options].filter(o => o.selected).map(o => o.value)
        
        nameArray.forEach((name) => {
            
            if (name.includes('.')) {
                
                const nameArray = name.split('.');
                this.setState({
                    [nameArray[0]]: {
                        ...this.state[nameArray[0]],
                        [nameArray[1]]: value
                    }
                });
                
            } else if (name.includes('--')) {
                
                const nameArray = name.split('--');
                let array = this.state[ nameArray[0] ];
                array[ nameArray[1] ] = value;
                
                this.setState({
                    [nameArray[0]]: array
                });
                
            } else {
                this.setState({ [name]: value });
            }
        })
        
    }

    componentDidMount() {
        const _this = this;

        this.repasRef.on('value', function(snapshot) {
            let repas;

            repas = snapshot.val() ? Object.keys(snapshot.val()).map( key => {
                let obj = snapshot.val()[key];
                obj.id = key;
                return obj;
            }) :
            repas = [];

            _this.setState({ repas })
            localStorage.setItem('repas', JSON.stringify(repas));
        });
    }

    // Et on supprime le listener lorsque le composant est détruit
    componentWillUnmount() {
        this.repasRef.off();
    }

    render() {
        const filteredRepas = this.getFilteredRepas();

        return (
        <div className="pb-2">  
            <h2>Les repas</h2>

            <div className="form-group">
                <label htmlFor="autocomplete" className="form-label">Filtrer</label>
                <input id="autocomplete" type="text" name="autocomplete" className="form-input" onChange={this.handleInputChange} />
            </div>

            <div className="form-group">
                <label htmlFor="category" className="form-label">Catégories</label>
                <select className="form-select" type="text" id="category" name="category" value={this.state.category} onChange={this.handleInputChange}>
                    <option value="">- Choisir -</option>
                    { categories.map(c => <option key={c.slug} value={c.slug}>{c.label}</option> ) }
                </select>
            </div>

            {filteredRepas.map((el, i) =>
                <div key={"repas-"+i} className="mt-2 p-1">
                    <div className="text-right">

                        <Link to={`/repas/${el.id}/edit`} className="btn btn-primary btn-lg btn-action">
                            <i className="icon icon-edit"></i>
                        </Link>

                        <button className="btn btn-primary btn-lg btn-action btn-error"  onClick={ () => {this.deleteRepas(el.id, el.label)} }>
                            <i className="icon icon-delete"></i>
                        </button>

                    </div>
                    <Repas repas={el} />
                </div>
            )}
        </div>
        )
    }
}
