import React, { Component } from 'react'
import firebase from 'firebase/app';
import 'firebase/database';

import { categories } from '../config'

const initialState = {
    label: '',
    ingredients: []
}

export default class RepasForm extends Component {
    constructor(props) {
        super(props);
        
        this.state = {...initialState}
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addIngredient = this.addIngredient.bind(this);
        this.removeIngredient = this.removeIngredient.bind(this);
    }
    
    addIngredient() {
        this.setState({
            ingredients: [...this.state.ingredients, '']
        })
    }
    
    removeIngredient(i) {
        this.setState({
            ingredients: [...this.state.ingredients.slice(0,i), ...this.state.ingredients.slice(i+1)]
        })
    }
    
    handleInputChange(event) {
        const target = event.target;        
        let value = target.type === 'checkbox' ? target.checked : target.value;
        
        let isArray = target.name.includes('[]');
        let name = target.name;
        let nameArray = name.split(',');
        
        if (target.multiple) value = [...event.target.options].filter(o => o.selected).map(o => o.value)
        
        nameArray.forEach((name) => {
            
            if (name.includes('.')) {
                
                const nameArray = name.split('.');
                this.setState({
                    [nameArray[0]]: {
                        ...this.state[nameArray[0]],
                        [nameArray[1]]: value
                    }
                });
                
            } else if (name.includes('--')) {
                
                const nameArray = name.split('--');
                let array = this.state[ nameArray[0] ];
                array[ nameArray[1] ] = value;
                
                this.setState({
                    [nameArray[0]]: array
                });
                
            } else {
                this.setState({ [name]: value });
            }
        })
        
    }
    
    handleSubmit(event) {
        event.preventDefault();
        
        // A post entry.
        var postData = {...this.state};
        
        // Get a key for a new Post.
        var postKey = firebase.database().ref().child('repas').push().key;
        
        // Update or new
        if (postData.id) {
            postKey = postData.id;
            delete postData.id;
            alert('Repas mis à jour');
        } else {
            this.setState(initialState)
        }
                
        // Write the new post's data simultaneously in the repas list and the user's post list.
        var updates = {};
        updates['/repas/' + postKey] = postData;
        
        return firebase.database().ref().update(updates);
    }
    
    componentDidUpdate(prevProps, prevState) {
        console.log('this.props', this.props);
        if (!prevProps.repas && this.props.repas) {
            this.setState({
                ...this.props.repas
            })
        }
    }
    
    componentDidMount() {
        // if (this)
    }
    
    render() {
        return (
            <form className="card" onSubmit={this.handleSubmit}>
                <div className="card-header">
                
                    <div className="form-group mb-2 pb-2">
                        <label htmlFor="label" className="form-label">Label</label>
                        <input className="form-input" type="text" id="label" name="label" value={this.state.label} onChange={this.handleInputChange} required />
                    </div>
                    
                    <h6 className="mt-2 pb-1">
                    Ingrédients <span className="ml-1 s-circle btn btn-primary btn-action circle btn-sm" onClick={this.addIngredient}>+</span>
                    </h6>
                    {this.state.ingredients.map((el, i) =>
                        <div key={"ingredient__"+i} className="input-group mb-2 mt-2">
                            <input className="form-input" type="text" id="label" name={"ingredients--"+i} placeholder="Ingrédient" value={this.state.ingredients[i]} onChange={this.handleInputChange} />
                            <div className="btn btn-primary input-group-btn" onClick={() => { this.removeIngredient(i) } }>&times;</div>
                        </div>
                    )}          
                    
                    <div className="form-group mb-2 pb-2">
                        <label htmlFor="recette" className="form-label">Recette</label>
                        <textarea className="form-input" name="recette" onChange={this.handleInputChange} id="recette" cols="30" rows="5" value={this.state.recette}></textarea>
                    </div>   

                    <div className="form-group mb-2 pb-2">
                        <label htmlFor="category" className="form-label">Catégorie</label>
                        <select className="form-select" type="text" id="category" name="category" value={this.state.category} onChange={this.handleInputChange}>
                            <option value="">- Choisir -</option>
                            { categories.map(c => <option key={c.slug} value={c.slug}>{c.label}</option> ) }
                        </select>
                    </div>          
                </div>
                    
                <div className="card-footer">
                    <button className="btn btn-primary">Valider</button>
                </div>
            </form>
        )
    }
}
    