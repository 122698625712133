import React, { Component } from 'react'

export default class Repas extends Component {
    render() {
        const { repas } = this.props
        return (
        <div className="card">
            <div className="card-header">
                <div className="card-title h5">{repas.label}</div>   
                {repas.category && <div className="chip bg-primary">{repas.category}</div> }
            </div>

            {repas.ingredients &&
                <div className="card-body">
                    <h6>Ingrédients</h6>
                    {repas.ingredients && repas.ingredients.map((el, i) =>
                        <div className="chip" key={"ingredients-"+i}>{repas.ingredients[i]}</div>
                    )}

                    { repas.recette && 
                        <div className="pt-2">
                            <div className="divider mt-2 mb-2 pb-2"></div>
                            <h6 className="">Recette</h6>
                            <p className="mb-0">{repas.recette}</p>
                        </div>
                    }
                </div>
            }
        </div>
        )
    }
}
